import React from 'react';

import Layout from '../../layouts';
import PageTitle from '../../components/Subpage/title';
import Container from '../../components/Container';
import Login from '../../components/Login';

const page = { name: 'Login', slug: 'account/login' };

export default function LoginPage({ location }) {
  const prevPage = location.state && location.state.prevPage;
  return (
    <Layout title={page.name} location={`/${page.slug}/`} prevPage={prevPage}>
      <PageTitle title={page.name} />
      <Container>
        <Login />
      </Container>
    </Layout>
  );
}
